<template>
  <div class="page-report">
    <div v-if="loading" class="loading-mask" v-loading="loading"></div>

    <div class="report-template">
      <div class="gradient-bg"></div>
      <img v-if="logoUrl" class="pdm-logo" :src="logoUrl" alt="" />
      <div class="report-name"> PDM Analysis Report</div>

      <div class="template-box">
        <p class="title"><span class="title-circle"></span>Report Information</p>
        <table class="song-table">
          <tr>
            <th class="col-1">Track Name</th>
            <th class="col-2">Artist</th>
            <th v-if="regionDict[songData.artist_country] || isDSP" class="col-3">Artist Country</th>
            <th class="col-4">Play</th>
            <th v-if="isDSP" class="col-4">PDM Rating/Score</th>
            <th class="col-4">UPC</th>
          </tr>
          <tr>
            <td class="col-1">
              <p class="track-name" :title="songData.track_name">{{songData.track_name}}</p>
            </td>
            <td class="col-2">
              <p class="artist-name" :title="songData.artist_name">{{songData.artist_name || '--'}}</p>
            </td>
            <td v-if="regionDict[songData.artist_country] || isDSP" class="col-3">{{regionDict[songData.artist_country] || 'Others'}}</td>
            <td class="col-4">
              <i
                class="player-icon"
                :class="isAudioPlay && songData.audio_link === mp3Url? 'icon-pause1' : 'icon-play1'"
                @click="playMusic(songData.audio_link,songData.track_name)"
              >
              </i>
            </td>
            <td v-if="isDSP" class="col-4">{{songData.level ? songData.level + '/' + songData.score : '--'}}</td>
            <td class="col-5 report-time">
              <p>{{songData.UPC || '--'}}</p>
              <img v-if="songData.level === 'S'" class="pdm-stamp" src="~/assets/stamp.png">  
            </td>
          </tr>
        </table>
      </div>

      <audio-player 
          class="audio-player"
          ref="audioPlayer" 
          :mp3-url="mp3Url" 
          :mp3-name="mp3Name"
          @updateAudioStatus="updateAudioStatus">
        </audio-player>
      <div class="template-box" v-if="showPlaylist">
        <p class="title"><span class="title-circle"></span>Playlist Analysis</p>
        <playlist-analysis :models ="models" :pdm-level="songData.level" :artist-region="songData.artist_country" :platform-data="platformData" :is-gospel="isGospel"></playlist-analysis>
      </div> 

      <div class="template-box" v-if="showAudienceProfile">
        <p class="title"><span class="title-circle"></span>Audience Profile Prediction</p>
        <audience-profile-prediction :models ="models" :pdm-level="songData.level" :artist-region="songData.artist_country" :region-data="audienceData"></audience-profile-prediction>
      </div>

      <div class="template-box short-video" v-if="showPromotion">
        <p class="title"><span class="title-circle"></span>Short-video Promotion Analysis</p>
        <short-video-promotion 
          :is-audio-play="isAudioPlay"
          :song-data="songData" 
          :promotion-obj="promotionObj"
          @handleMusic="handleMusic"
        >
        </short-video-promotion>
      </div>
      
      <!-- <p class="email-us">For any enquiries, please email us at PDM@tencent.com. </p>
      <img class="pdm-bg" src="../../assets/pdm-bg.png"> -->
      
    </div>
  </div>
</template>

<script>
import AudienceProfilePrediction from '../common/__audience-profile-prediction'
import PlaylistAnalysis from '../common/__playlist-analysis'
import ShortVideoPromotion from '../common/__short-video-promotion'

export default {
  components: {
    AudienceProfilePrediction,
    ShortVideoPromotion,
    PlaylistAnalysis
  },

  data() {
    return {
      isDSP:this.$route.path.includes('/report-d'),
      report_id: this.$util.getParams(window.location.href).report_id,
      platformData: {},
      showPlaylist: false,
      songData: {
        track_name: "",
        artist_name: "",
        artist_country: "",
        update_time: "",
        level: "",
        UPC:''
      },
      mp3Url:'',
      mp3Name:'not available',
      isAudioPlay: false,
      loading: true,
      regionDict: {
        'GH':'Ghana',
        'KE':'Kenya',
        'NG':'Nigeria',
        'ZM':'Zambia',
        'UG':'Uganda'
      },
      showAudienceProfile:true,
      audienceData: [],
      showPromotion: true,
      promotionObj: {},
      logoUrl:'',
      models:[],
      isGospel: false
    }
  },

  async mounted() {
    await this.getData()
    await this.getLogo()
    localStorage.setItem("lang", "en")
    this.$i18n.locale = 'en'
    this.mp3Url = this.songData.audio_link
    this.mp3Name = this.songData.track_name
    setTimeout(() => {
      this.$refs.audioPlayer && this.$refs.audioPlayer.pauseMusic()
      this.loading = false
    }, 300)
  },
    
  methods: {
    async getData(){
      const task_id = this.$util.getParams(window.location.href).task_id || ''
      const result = await this.$store.dispatch("getAfrotunesReport", {
        taskid: task_id,
        songid: this.report_id
      })
      if (result.code === 2000) {
        this.handleData(result.reports)
        this.models= result.reports.models
      } else {
        this.$message({
          message: result.code === 4002 ? 'The report does not exist.' : result.msg,
          type: "error"
        })
      }
    },
    async getLogo(){
      const result =  await this.$store.dispatch("getLogo", {
        songid: this.report_id,
      });
      if(result.code === 2000 && result.logo_url){
        this.logoUrl = result.logo_url
      } else {
        this.logoUrl = 'https://tme-chuangxin-hw-1258344705.cos.ap-hongkong.myqcloud.com/share/pdm-email-file/pdm-logo-black.png'
      }
    },
    handleData(report){
      let time = report.create_time.substring(0,report.create_time.indexOf("T"));
      this.songData = report
      this.songData.update_time = time
      this.isGospel = this.songData.tags && this.songData.tags.is_gospel
      // 歌单
      if (report.playlist && typeof report.playlist !=='string') {
        this.showPlaylist = true
        setTimeout(() => {
          this.platformData = report.playlist.playlist || {}
        }, 100)
      } else {
        this.showPlaylist = false
      }
      
      // 受众分析
      if (report.audience_profile && typeof report.audience_profile!='string'){
        this.audienceData = report.audience_profile.region_analysis[0]
      } else {
        this.audienceData = []
        this.showAudienceProfile = false
      }

      // 宣推建议
      if (report.short_video && typeof report.short_video !== 'string' && report.short_video.clips) {
        this.promotionObj = report.short_video.clips[0]
      } else {
        this.showPromotion = false
      }
    },
    
    playMusic(url,name) {
      this.mp3Url = url
      this.mp3Name =name
      console.log(this.isAudioPlay)
      if (this.isAudioPlay) {
        this.$refs.audioPlayer.pauseMusic()
      } else {
        this.$refs.audioPlayer.playMusic()
      }
    },

    updateAudioStatus (status) {
      this.isAudioPlay = status
    },

    handleMusic (obj) {
      if (obj.status === 'pause' && obj.url===this.mp3Url) {
        this.$refs.audioPlayer.pauseMusic()
      } else {
        this.playMusic(obj.url,obj.name)
      }
    },
  }
}
</script>

<style lang="stylus" scoped>
.page-report {
  width: 100%;
  padding: 100px 0;
}
.loading-mask {
  position: absolute;
  width: 100vw; 
  height: 100vh; 
  top: 0;
  left: 0;
  background: rgba(255,255,255,0.95);
  z-index: 1000;
}
.report-template {
  position: relative;
  padding: 40px;
  margin: 0 auto;
  width: 900px;
  background-color #fff;
  color: #333;
  font-size: 14px;
  user-select: text;

  // .gradient-bg {
  //   position: absolute;
  //   z-index: 1;  
  //   top: 0;
  //   left: 0;
  //   opacity: 0.25;
  //   background: linear-gradient(to bottom, #FF9700 0%, #fff 100%);
  //   width: 980px;
  //   height: 160px;
  // }

  .pdm-logo {
    position: relative;
    z-index: 2;
    width: 160px;
    margin-top: 10px;
  }

  .report-name {
    font-size: 32px;
    text-align: left;
    color: #FF9700;
    margin-top: 30px;
    font-family: 'Gilroy Bold';
  }

  .song-table {
    width: 100%;
    border-collapse:collapse;
    border-radius: 6px;
    overflow: hidden;
    th {
      height: 40px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 600;
      font-size: 14px;
      padding: 10px;
    }
    td {
      height: 66px;
      padding: 10px;
    }
    tr:nth-child(2n) {
      background-color: #fbfbfb;
    }
    tr:nth-child(2n+1) {
      background-color: #f3f3f3;
    }
    .report-time {
      position: relative;

      .pdm-stamp {
        position: absolute;
        opacity: 0.4;
        width: 80px;
        top: 2px;
        right: -15px;
        transform: rotate(-15deg);
      }
    }
    .col-1 {
      text-align: left;
    }
    .col-2, .col-3, .col-4, .col-5 {
      text-align: center;
    }
    .col-1 {
      width: 180px;
      padding-left: 40px !important;
    }
    .col-2 {
      width: 140px;
    }
    .col-3 {
      width: 100px;
    }
    .track-name {
      width: 180px;
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
      font-size: 15px;
      line-height: 66px;
      color: #000;
    }
    .artist-name {
      font-size: 17px;
      font-family: 'Gilroy Bold';
      font-weight: bold;
      color: #FF9700;
      line-height: 66px;
      width: 140px;
      overflow: hidden; 
      text-overflow: ellipsis; 
      white-space: nowrap;
    }
    .player-icon {
      color: #aaa;
      cursor: pointer;
      font-size: 28px;
      line-height: 30px;
    }
  }

  .template-box {
    margin: 0 auto 20px;
    padding: 10px 0;
    width: 100%;
    .second-title {
      line-height: 30px;
      opacity: 0.85;
    }
    .title {
      font-size: 20px;
      margin: 20px 0;
      line-height: 30px;
      font-weight: 600;
    }
    .title-circle {
      display: inline-block;
      height: 11px;
      width: 11px;
      border-radius: 11px;
      background: #FF9700;
      margin: 0 5px 3px 0;
    }
    .track-img{
      width: 1000px;
      margin: 0 50px;
    }
    .video-type {
      display: flex;
      align-items: center;
      margin: 10px 0 15px 0;
    }
    .video-type-group {
      flex: 1;
      font-size: 16px;
      margin-top: 5px;
    }
    .video-list{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
    }
    .video-item{
      width: 260px;
      height: 450px;
      background-color: #eee;
      border-radius: 8px;
      margin: 0 160px 80px 0;
    }
    .video-item:nth-child(3n) {
      margin: 0 0 80px 0;
    }
    .tags-box{
      margin: 20px 0;
      border: 1px solid #dedede;
      border-radius: 4px;
      width: 50%;
      padding: 10px 0; 
      font-size: 16px;
      .tags{
        padding: 10px 20px;
        display:flex;
        flex-wrap: wrap;
        height: 27px;
        line-height: 27px;
      }
      .tag-type{
        margin: 0 5px;
      }
      .tag{
        padding: 0 20px;
        margin: 0 10px;
        background-color: #fff4eb;
        border-radius: 25px;
        border: 1px solid #dedede;
      }
    }
  }
  .email-us {
    width: 900px;
    text-align: center;
    margin: 100px 0 20px;
    font-size: 13px;
    color: #bbb;
    user-select: text;
  }
  .pdm-bg {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 240px;
  }
  ::v-deep .el-slider__stop {
    background: #e4e7ed;
  }
}
.audio-player {
  margin: 0 50px;
}
</style>